import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import loadable from '@loadable/component'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/ButtonLink'))
const Section = loadable(() => import('/src/components/Section'))

const SuccessHeader = ({ appointment }) => {
    const [leadId, setLeadId] = useState('')
    const [sourceId, setSourceId] = useState('')
    const [crCid, setCrCid] = useState('')

    useEffect(() => {
        setLeadId(sessionStorage.getItem('leadId'))
        setSourceId(sessionStorage.getItem('sourceId'))
        setCrCid(sessionStorage.getItem('crCid'))
    }, [])

    return (
        <Section>
            <div className="c-success-header">
                <StaticImage
                    src="../../images/illustrations/success.png"
                    width={550}
                    height={408}
                    alt="Illustration woman jumping in front of Protect Line logo"
                />
                <h1 className="c-success-header__title">
                    Thank you for your request!
                </h1>
                <p className="c-success-header__subtext">
                    <span>Protect Line</span> - Our FCA regulated experts will
                    handle it from here and provide you with a life insurance
                    quote. They'll reach out to you almost immediately - so look
                    out!
                </p>

                {appointment && (
                    <ButtonLink
                        data={{
                            url:
                                'https://protectline.co.uk/book-appointment-prequalified',
                            title: 'Book appointment'
                        }}
                        size={'lg'}
                        icon={'arrow'}
                        utm={`?lid=${leadId}&sourceID=${sourceId}&cr_cid=${crCid}`}
                    />
                )}
            </div>
        </Section>
    )
}

SuccessHeader.propTypes = {
    appointment: PropTypes.bool
}

export default SuccessHeader
